import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"margin": "0 0 24px 0",
			"children": "FAQs"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Jakie dokumenty są potrzebne do zakupu nieruchomości przez HarborView Realty?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": <>
				Aby zakupić nieruchomość za pośrednictwem HarborView Realty, będziesz potrzebować następujących dokumentów:
				<br />
				Dowód tożsamości (np. paszport, dowód osobisty)
				<br />
				Zaświadczenie o dochodach lub wyciąg z konta bankowego
				<br />
				Potwierdzenie wpłaty zaliczki
				<br />
				Umowa przedwstępna (jeśli dotyczy)
				<br />
				Dokumenty potwierdzające zdolność kredytową (jeśli korzystasz z kredytu hipotecznego)
			</>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"width": "33.333%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Jak długo trwa proces zakupu nieruchomości?"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Czas potrzebny na zakup nieruchomości może się różnić w zależności od wielu czynników, takich jak rodzaj nieruchomości, stan prawny nieruchomości oraz sposób finansowania. Średnio proces zakupu trwa od kilku tygodni do kilku miesięcy. Nasz zespół stara się jak najbardziej przyspieszyć ten proces, zapewniając wsparcie na każdym etapie, od negocjacji po finalizację transakcji."
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"width": "33.333%",
			"padding": "16px 16px 16px 16px",
			"md-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Czy mogę skonsultować się z agentem nieruchomości przed podjęciem decyzji?"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": <>
				Oczywiście! W HarborView Realty zachęcamy do skonsultowania się z naszymi agentami nieruchomości przed podjęciem jakiejkolwiek decyzji. Nasz zespół jest dostępny, aby odpowiedzieć na wszystkie Twoje pytania, przedstawić dostępne opcje i pomóc Ci w dokonaniu najlepszego wyboru.{" "}
			</>
		}
	}
};

const Faq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Text {...override("text")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text1")} />
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Text {...override("text3")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Text {...override("text5")} />
					<Text {...override("text6")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq, { ...Section,
	defaultProps,
	overrides
});
export default Faq;